
import { Component, Vue } from "vue-property-decorator";
import {
  deleteMintosLoanOriginatorsStatisticsForToday,
  refreshCaches,
  runMintosLoanOriginatorsUpdater,
  runPlatformStatisticsUpdater,
} from "@/api/admin-api";

interface RunnerState {
  isLoading: boolean;
}

function idleRunnerState(): RunnerState {
  return { isLoading: false };
}

function loadingRunnerState(): RunnerState {
  return { isLoading: true };
}

@Component({ components: {} })
export default class Data extends Vue {
  platformStatisticsRunnerState = idleRunnerState();
  mintosLoanOriginatorsRunnerState = idleRunnerState();
  deleteMintosLoanOriginatorsForTodayRunnerState = idleRunnerState();

  get shouldShowDeleteMintosLoanOriginatorsStatisticsForToday(): boolean {
    return process.env.VUE_APP_BEYONDP2P_ENVIRONMENT !== "prod";
  }

  async userClickedRefreshCaches(): Promise<void> {
    return refreshCaches()
      .then(() => this.$noty.success("Caches have been refreshed."))
      .catch((error) =>
        this.$noty.error(`Cache refresh failed with error: ${error}`)
      );
  }

  async userRunPlatformStatisticsUpdater(): Promise<void> {
    this.platformStatisticsRunnerState = loadingRunnerState();

    return runPlatformStatisticsUpdater()
      .then(() =>
        this.$noty.success("Platform statistics updater run successfully.")
      )
      .catch((error) =>
        this.$noty.error(
          `Platform statistics updater failed with error: ${error}`
        )
      )
      .finally(() => (this.platformStatisticsRunnerState = idleRunnerState()));
  }

  async userRunMintosLoanOriginatorUpdater(): Promise<void> {
    this.mintosLoanOriginatorsRunnerState = loadingRunnerState();

    return runMintosLoanOriginatorsUpdater()
      .then(() =>
        this.$noty.success("Mintos loan originators updater run successfully.")
      )
      .catch((error) =>
        this.$noty.error(
          `Mintos loan originator updater failed with error: ${error}`
        )
      )
      .finally(
        () => (this.mintosLoanOriginatorsRunnerState = idleRunnerState())
      );
  }

  async userClickedDeleteMintosLoanOriginatorStatisticsForToday(): Promise<void> {
    this.deleteMintosLoanOriginatorsForTodayRunnerState = loadingRunnerState();

    return deleteMintosLoanOriginatorsStatisticsForToday()
      .then(() =>
        this.$noty.success(
          "Today's mintos loan originators statistics successfully deleted."
        )
      )
      .catch((error) =>
        this.$noty.error(
          `Failed to deleted today's mintos loan originator statistics: ${error}`
        )
      )
      .finally(
        () =>
          (this.deleteMintosLoanOriginatorsForTodayRunnerState =
            idleRunnerState())
      );
  }
}
