
import { Component, Vue } from "vue-property-decorator";
import SniperBotMessageForm from "@/components/sniper/SniperBotMessageForm.vue";
import SniperNav from "@/components/sniper/SniperNav.vue";

@Component({
  components: {
    SniperNav,
    SniperBotMessageForm,
  },
})
export default class Sniper extends Vue {}
