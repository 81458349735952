
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import {
  LOADING,
  SUCCESS,
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  Country,
  createPlatform,
  CreatePlatformRequest,
  getPlatformById,
  Platform,
  PlatformId,
  Qualifier,
  updatePlatform,
  UpdatePlatformRequest,
} from "@/api/admin-api";
import { whenAppIsInitialized } from "@/service/app-init-service";
import { getCountryName } from "@/service/country-codes-service";
import { Action } from "@/store/actions";
import { VueEditor } from "vue2-editor";

interface FormData {
  id: PlatformId;
  qualifier: Qualifier;
  name: string;
  slug: string;
  showReview: boolean;
  showComparison: boolean;
  showMarketData: boolean;
  sharesDailyStatistics: boolean;
  affiliateLink: string;
  affiliateCashBack: string;
  affiliateNotRecommend: boolean;
  description: string;
  country: Country;
}

type FormMethod = "create" | "update";

@Component({ components: { LoadingIndicator, VueEditor } })
export default class PlatformForm extends Vue {
  private CREATE_METHOD: FormMethod = "create";
  private UPDATE_METHOD: FormMethod = "update";
  private readonly VALIDATION_ERROR_STATUS_CODE = 422;
  method: FormMethod = this.UPDATE_METHOD;

  @Prop({ required: false }) platformId?: PlatformId;
  platform: WebData<AxiosError, Platform> = webNotAsked();

  show = true;
  isFormControlsDisabled = true;
  formData: FormData = this.emptyFormData();

  async created() {
    await whenAppIsInitialized;

    if (this.platformId != null) {
      this.method = this.UPDATE_METHOD;
      await this.fetchData();
    } else {
      this.method = this.CREATE_METHOD;
    }
  }

  get isFormDisabled() {
    return this.platform.kind === LOADING;
  }

  get isFieldUpdateDisabled() {
    return this.method === "update";
  }

  get countriesOptions() {
    return this.$store.getters.getConstants.countries
      .map((isoCode) => ({
        value: isoCode.toLowerCase(),
        text: getCountryName(isoCode),
      }))
      .sort(function (a, b) {
        return a.text.localeCompare(b.text);
      });
  }

  async fetchData() {
    this.platform = webLoading();
    return getPlatformById(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(platform: Platform): PlatformId {
    this.isFormControlsDisabled = true;
    this.formData = this.webDataToFormData(platform);
    this.platform = webSuccess(platform);
    return platform.id;
  }

  private loadingSuccessCreate(platform: Platform) {
    const platformId = this.loadingSuccess(platform);
    this.$router.push(`/platforms/${platformId}`);
    this.$noty.success("The platform has been created");

    // Reload platforms map to display newly created platform correctly.
    this.$store.dispatch(Action.APP_LOADS_PLATFORMS_MAP);
  }

  private loadingSuccessUpdate(platform: Platform) {
    this.loadingSuccess(platform);
    this.$noty.success("The platform has been updated");
  }

  private loadingError(error: AxiosError) {
    this.platform = webError(error);
    this.$noty.error("Failure to load platform data");
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.platform = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform data");
      return;
    }
    this.platform = webError(error);
    this.$noty.error("Failure to save platform data");
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }

  onChange() {
    this.isFormControlsDisabled = false;
  }

  normalizeQualifier() {
    this.formData.qualifier = this.formData.qualifier
      .trim()
      .toUpperCase()
      .replaceAll("-", "_")
      .replaceAll(" ", "_");
  }

  normalizeSlug() {
    this.formData.slug = this.formData.slug
      .trim()
      .toLowerCase()
      .replaceAll("_", "-")
      .replaceAll(" ", "-");
  }

  onSubmit(): void {
    const { formData } = this;

    if (this.method === this.CREATE_METHOD) {
      const payload: CreatePlatformRequest = formData;
      createPlatform(payload)
        .then(this.loadingSuccessCreate)
        .catch(this.loadingErrorSave);
    } else if (this.method === this.UPDATE_METHOD) {
      const {
        id,
        name,
        showReview,
        showComparison,
        showMarketData,
        sharesDailyStatistics,
        affiliateLink,
        affiliateCashBack,
        affiliateNotRecommend,
        description,
        country,
      } = formData;
      const payload: UpdatePlatformRequest = {
        name,
        showReview,
        showComparison,
        showMarketData,
        sharesDailyStatistics,
        affiliateLink,
        affiliateCashBack,
        affiliateNotRecommend,
        description,
        country,
      };
      updatePlatform(id, payload)
        .then(this.loadingSuccessUpdate)
        .catch(this.loadingErrorSave);
    }
  }

  onReset(): void {
    this.formData =
      this.platform.kind === SUCCESS
        ? this.webDataToFormData(this.platform.data)
        : this.emptyFormData();
    this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private webDataToFormData(platform: Platform): FormData {
    return {
      id: platform.id,
      qualifier: platform.qualifier,
      name: platform.name,
      slug: platform.slug,
      showReview: platform.showReview,
      showComparison: platform.showComparison,
      showMarketData: platform.showMarketData,
      sharesDailyStatistics: platform.sharesDailyStatistics,
      affiliateLink: platform.affiliateLink,
      affiliateCashBack: platform.affiliateCashBack,
      affiliateNotRecommend: platform.affiliateNotRecommend,
      description: platform.description,
      country: platform.country,
    };
  }

  private emptyFormData(): FormData {
    return {
      id: null,
      qualifier: null,
      name: "",
      slug: null,
      showReview: true,
      showComparison: true,
      showMarketData: true,
      sharesDailyStatistics: false,
      affiliateLink: "",
      affiliateCashBack: "",
      affiliateNotRecommend: false,
      description: "",
      country: null,
    };
  }
}
