
import { Vue, Component, Prop } from "vue-property-decorator";

export type ActiveTab = "messaging" | "platforms";

@Component
export default class PlatformsEditNav extends Vue {
  @Prop() active: ActiveTab;

  isActive(testActive: ActiveTab): boolean {
    return testActive === this.active;
  }
}
